import { Component, EventEmitter, OnDestroy, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, filter, merge, of, Subject, Subscription, switchMap, tap } from 'rxjs';
import moment from 'moment';
import _ from 'underscore';

import { SharePrimeNGModule } from '../share-primeng.module';
import { TableComponent } from '../shared-ui/common/table/table.component';
import { ToolbarModel } from '../shared-ui/common/toolbar/toolbar.component';
import { TYPES, ColumnModel, FilterConfigModel, ViewConfigModel } from '../shared-ui/common/table/table.model';
import { FormComponent } from '../shared-ui/common/form/form.component';
import { atLeastOneIn, notEqualTo, quantityGreaterThanValidator } from '../shared-ui/validators/validators';
import { OrderService } from '../services/order.service';
import { Order } from '../models/order.model';
import { environment } from '../../environments/environment';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [TableComponent, SharePrimeNGModule, PdfViewerComponent],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css',
  providers: [DialogService, MessageService]
})
export class OrdersComponent implements OnDestroy {
  ref: DynamicDialogRef | undefined;

  totalRecords: number = 0;

  subscriptions: Subscription = new Subscription();

  cols: ColumnModel[] = [
    { field: 'id', header: 'ID', width: '5%', sortable: true, template: 'idColTemplate' },
    { field: 'status', header: 'Status', width: '10%', template: 'statusTemplate' },
    { field: 'order_variants', header: 'Mockup Items', width: '50%', template: 'designItemTemplate' },
    { field: 'shipping_method', header: 'Labels/Tracking Number', width: '10%', template: 'trackingTemplate' },
    { field: 'totalCost', header: 'Total Cost', width: '5%', template: 'currencyTemplate' },
    { field: 'created_time', header: 'Created Time', width: '6%', template: 'dateTemplate' },
    { field: 'modified_time', header: 'Updated Time', width: '6%', template: 'dateTemplate' },
    { field: 'processedTimeInHours', header: 'Process Time', width: '5%', template: 'durationTemplate' },
    { field: 'action', header: '', width: '3%', template: 'actionTemplate' },
  ];

  viewConfigs: ViewConfigModel = {
    views: [],
    action: (name: string) => {
      console.log(name);
      const filterValue = this.filter$.getValue();
      this.filter$.next({
        ...filterValue,
        skip: 0,
        conditions: {
          ...filterValue.conditions,
          fulfillStatus: name
        }
      });
    }
  };

  toolbarConfigsForOrderItem: ToolbarModel = {
    leftButtons: [
      {
        label: '',
        icon: 'pi-plus',
        severity: 'success',
        event: new EventEmitter().pipe(
          tap(() => {
            const newItem: any = {
              variant: null,
              product: null,
              color: null,
              size: null,
              quantity: null,
              mockupFront: null,
              mockupBack: null,
              designFront: null,
              designBack: null,
              selected: false,
              images: []
            };

            this.listSectionConfig.listSectionConfigSignal!.update((section: any) => {
              const key = section.key;
              const items = section[key];
              return {
                ...section,
                [key]: [
                  ...items,
                  newItem
                ]
              }
            });
          })
        ),
        size: 'small'
      },
      {
        label: 'Delete',
        icon: 'pi-trash',
        severity: 'danger',
        event: new EventEmitter(),
        size: 'small'
      }
    ]
  };

  sellerFilter: FilterConfigModel<'seller'> = {
    label: 'Select a Seller',
    type: TYPES.DROPDOWN,
    optionLabel: 'seller',
    hasFilter: true,
    options: [],
    key: 'seller',
    occupy: 4
  };

  storeFilter: FilterConfigModel<'store'> = {
    label: 'Select a Store',
    type: TYPES.DROPDOWN,
    optionLabel: 'store',
    hasFilter: true,
    options: [],
    key: 'store',
    occupy: 4
  };

  priorityOrderFilter: FilterConfigModel<'option'> = {
    label: 'Select order priority',
    type: TYPES.DROPDOWN,
    optionLabel: 'option',
    options: [
      {
        option: '-', value: undefined
      }, {
        option: 'Priority', value: true
      }, {
        option: 'Non Priority', value: false
      }
    ],
    key: 'isPriorityOrder',
    occupy: 2
  };

  oversizeOrderFilter: FilterConfigModel<'option'> = {
    label: 'Select order oversize',
    type: TYPES.DROPDOWN,
    optionLabel: 'option',
    options: [
      {
        option: '-', value: undefined
      }, {
        option: 'Oversize', value: true
      }, {
        option: 'None', value: false
      }
    ],
    key: 'isOversizeOrder',
    occupy: 2
  };

  labelTrackingFilter: FilterConfigModel<'option'> = {
    label: 'Select a Label Tracking',
    type: TYPES.DROPDOWN,
    optionLabel: 'option',
    options: [
      {
        option: '-', value: undefined
      }, {
        option: 'Has Label', value: 'yes'
      }, {
        option: 'No Label', value: 'no'
      }
    ],
    key: 'labelTracking',
    occupy: 2
  };

  fulfillStatusFilter: FilterConfigModel<'status'> = {
    label: 'Select a Status',
    type: TYPES.DROPDOWN,
    optionLabel: 'status',
    hasFilter: true,
    options: [],
    key: 'fulfillStatus',
    occupy: 4
  };

  quantityFilter: FilterConfigModel<'no'> = {
    label: 'Select Quantity',
    type: TYPES.DROPDOWN,
    optionLabel: 'no',
    options: Array(11).fill(null).map((_, idx) => (!idx ? { no: '-', value: undefined } : { no: idx, value: idx })),
    key: 'quantity',
    occupy: 2
  };

  filterConfigs: FilterConfigModel<any>[] = [
    {
      label: 'Order ID',
      type: TYPES.INPUT,
      key: 'orderId',
      occupy: 4
    },
    {
      label: 'Ref ID',
      type: TYPES.INPUT,
      key: 'ref_id',
      occupy: 4
    },
    {
      label: 'Name',
      type: TYPES.INPUT,
      key: 'name',
      occupy: 4
    },
    this.sellerFilter,
    this.storeFilter,
    this.fulfillStatusFilter,
    this.labelTrackingFilter,
    this.quantityFilter,
    this.priorityOrderFilter,
    this.oversizeOrderFilter,
    {
      type: TYPES.BUTTONGROUP,
      buttons: [{
        label: 'Clear filters',
        severity: 'secondary',
        action: (formGroup: FormGroup) => {
          formGroup.reset();

          const filterValue = this.filter$.getValue();
          this.filter$.next({
            ...filterValue,
            conditions: {}
          });
        }
      },
      {
        label: 'Filter',
        severity: 'primary',
        action: (formGroup: FormGroup) => {
          const {
            fulfillStatus,
            labelTracking: trackingLabel,
            name,
            orderId,
            quantity,
            seller,
            store,
            refId,
            isPriorityOrder,
            isOversizeOrder,
          } = formGroup.getRawValue();

          const filterValue = this.filter$.getValue();

          this.filter$.next({
            ...filterValue,
            skip: 0,
            conditions: {
              fulfillStatus,
              trackingLabel,
              seller,
              store,
              orderId: orderId === '' ? undefined : orderId,
              refId,
              name,
              quantity,
              isPriorityOrder,
              isOversizeOrder
            }
          });
        }
      }],
      occupy: 4
    },
  ];

  sectionConfigs = [
    {
      section: 'Shipping Info',
      type: 'single',
      leftSide: [
        {
          type: TYPES.DROPDOWN,
          label: 'Priority Order',
          options: [{
            is_priority: 'Non-Priority',
            value: false
          },
          {
            is_priority: 'Priority',
            value: true
          },
          ],
          key: 'is_priority',
          value: false,
          placeholder: '',
          validators: [Validators.required],
          occupy: 3,
        },
        {
          type: TYPES.DROPDOWN,
          label: 'Oversize Order',
          options: [
            {
              is_oversize: 'Non-Oversize',
              value: false
            },
            {
              is_oversize: 'Oversize',
              value: true
            }],
          key: 'is_oversize',
          value: false,
          placeholder: '',
          validators: [Validators.required],
          occupy: 3,
        },
        {
          type: TYPES.INPUT,
          label: 'Ref ID',
          key: 'ref_id',
          placeholder: '',
          validators: [Validators.required],
          occupy: 6,
        },
        {
          type: TYPES.INPUT,
          label: 'First Name',
          key: 'first_name',
          placeholder: '',
          validators: [],
          occupy: 6,
        },
        {
          type: TYPES.INPUT,
          label: 'Last Name',
          key: 'last_name',
          placeholder: '',
          validators: [],
          occupy: 6,
        },
        {
          type: TYPES.DROPDOWN,
          label: 'Shipping Method',
          options: [{
            shipping_method: 'standard',
            value: 'standard'
          },
          {
            shipping_method: 'priority',
            value: 'priority'
          },
          ],
          key: 'shipping_method',
          value: 'standard',
          placeholder: '',
          validators: [Validators.required],
          occupy: 4,
        },
        {
          type: TYPES.INPUT,
          label: 'Label',
          key: 'shipping_label',
          placeholder: '',
          validators: [],
          occupy: 8,
          cascadeFor: 'tracking_number',
          httpRequest: (url: string) => {
            return this.utilityService.lookupReplacementUrl(url).pipe(
              switchMap(fn => this.orderService.getTrackingNumberByPdf(fn(url))),
              catchError(err => of({ trackingNumberOriginal: null })),
              switchMap((data: any) => {
                console.log(data)
                return of(data.trackingNumberOriginal);
              })
            );
          }
        },
        {
          type: TYPES.INPUT,
          label: 'Tracking Number',
          key: 'tracking_number',
          placeholder: '',
          readonly: true,
          validators: [notEqualTo('loading')],
          occupy: 12,
        },
      ],
      rightSide: [
        {
          type: TYPES.INPUT,
          label: 'Email',
          key: 'email',
          placeholder: 'email',
          validators: [Validators.email],
          occupy: 6,
        },
        {
          type: TYPES.INPUT,
          label: 'Phone',
          key: 'phone',
          placeholder: 'Phone',
          validators: [],
          occupy: 6,
        },
        {
          type: TYPES.INPUT,
          label: 'Address',
          key: 'street1',
          placeholder: 'Address line 1',
          validators: [],
          occupy: 6,
        },
        {
          type: TYPES.INPUT,
          label: 'Address 2',
          key: 'street2',
          placeholder: 'Apartment, studio or floor',
          validators: [],
          occupy: 6,
        },
        {
          type: TYPES.INPUT,
          label: 'City',
          key: 'city',
          placeholder: '',
          validators: [],
          occupy: 6,
        },
        {
          type: TYPES.INPUT,
          label: 'State',
          key: 'state',
          placeholder: '',
          validators: [],
          occupy: 6,
        },
        {
          type: TYPES.INPUT,
          label: 'Postcode',
          key: 'zip_code',
          placeholder: '',
          validators: [],
          occupy: 6,
        },
        {
          type: TYPES.INPUT,
          label: 'Country',
          key: 'country',
          placeholder: '',
          validators: [],
          occupy: 6,
        },
      ]
    },
  ];

  listSectionConfig = {
    section: 'Order Items',
    type: 'list',
    key: 'order_variants',
    common: {
      type: TYPES.TOOLBAR,
      configurations: this.toolbarConfigsForOrderItem,
      occupy: 12
    },
    eachItem: {
      type: TYPES.CHECKBOX,
      occupy: 12
    },
    listSectionConfigSignal: signal({}),
    hasImages: {
      imageOrder: [
        'mockup_front',
        'mockup_back',
        'front',
        'back',
        'sleeve_left',
        'sleeve_ight',
      ],
    },
    validators: {
      section: [quantityGreaterThanValidator(1, 'Order Items')],
      each: [
        atLeastOneIn(['mockup_front', 'mockup_back']),
        atLeastOneIn(['front', 'back', 'sleeve_left', 'sleeve_right'])
      ]
    },
    order_variants: [],
    occupy: {
      leftSide: 4,
      rightSide: 8
    },
    leftSide: [
      {
        type: TYPES.INPUT,
        label: 'Variant Id',
        key: 'variant',
        placeholder: '',
        validators: [Validators.required],
        data: [],
        occupy: 8,
        disabled: true
      },
      {
        type: TYPES.INPUTNUMBER,
        label: 'Qty',
        key: 'quantity',
        placeholder: '',
        validators: [Validators.required, Validators.min(1)],
        occupy: 4,
      },
      {
        type: TYPES.INPUT,
        label: 'Product',
        key: 'product_name',
        placeholder: '',
        validators: [Validators.required],
        occupy: 12,
      },
      {
        type: TYPES.DROPDOWN,
        label: 'Style, Color, Size',
        key: 'style-color-size',
        filterBy: 'search',
        options: [],
        placeholder: '',
        validators: [Validators.required],
        occupy: 12,
        cascadeFor: 'variant',
      },
    ],
    rightSide: [
      {
        type: TYPES.INPUT,
        label: 'Mockup Front',
        key: 'mockup_front',
        placeholder: '',
        validators: [],
        occupy: 6,
        listenOnChange: true,
      },
      {
        type: TYPES.INPUT,
        label: 'Mockup Back',
        key: 'mockup_back',
        placeholder: '',
        validators: [],
        occupy: 6,
        listenOnChange: true,
      },
      {
        type: TYPES.INPUT,
        label: 'Design Front',
        key: 'front',
        placeholder: '',
        validators: [],
        occupy: 6,
        listenOnChange: true,
      },
      {
        type: TYPES.INPUT,
        label: 'Design Back',
        key: 'back',
        placeholder: '',
        validators: [],
        occupy: 6,
        listenOnChange: true,
      },
      {
        type: TYPES.INPUT,
        label: 'Sleeve Left',
        key: 'sleeve_left',
        placeholder: '',
        validators: [],
        occupy: 6,
        listenOnChange: true,
      },
      {
        type: TYPES.INPUT,
        label: 'Sleeve Right',
        key: 'sleeve_right',
        placeholder: '',
        validators: [],
        occupy: 6,
        listenOnChange: true,
      },
    ]
  };

  toolbarConfigs: ToolbarModel = {
    leftButtons: [
      {
        label: 'New',
        icon: 'pi-plus',
        severity: 'success',
        event: new EventEmitter().pipe(
          tap(() => {
            this.ref = this.dialogService.open(FormComponent, {
              header: 'Add a new Order',
              width: '85vw',
              contentStyle: { overflow: 'auto' },
              data: {
                toolbarForSave: this.toolbarForSave,
                sectionConfigs: this.sectionConfigs,
                listSectionConfig: this.listSectionConfig,
              }
            });

            this.ref.onClose.pipe(
              filter(isAccepted => isAccepted)
            ).subscribe(() => {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Your order is created', life: 3000 });

              const [, syncButton] = this.toolbarConfigs.rightButtons!;
              (syncButton.event as EventEmitter<any>).emit();
            });
          }),
        ),
      },
      // {
      //   label: 'Delete',
      //   icon: 'pi-trash',
      //   severity: 'danger',
      //   event: new EventEmitter()
      // }
    ],
    rightButtons: [
      {
        label: 'Show Filter',
        icon: 'pi-filter',
        severity: 'secondary',
        event: new EventEmitter()
      },
      {
        label: 'Sync',
        icon: 'pi-sync',
        severity: 'info',
        text: true,
        event: new EventEmitter()
      }
    ]
  };

  toolbarForSave: ToolbarModel = {
    leftButtons: [],
    rightButtons: [
      {
        label: 'Cancel',
        icon: 'pi-undo',
        severity: 'danger',
        event: new EventEmitter().pipe(
          tap(() => this.ref?.close())
        ),
        size: 'small'
      },
      {
        label: 'Save',
        icon: 'pi-save',
        severity: 'success',
        disabled: true,
        event: new EventEmitter(),
        httpRequest: (data: any) => of(data).pipe(
          switchMap((data: any) => {
            console.log(data);
            const payload = Object.entries(data).reduce((acc: any, [key, value]: any) => {
              if (key !== 'order_variants' && _.isString(value) && value.trim() === '') {
                return acc;
              }
              return {
                ...acc,
                [key]: value
              };
            }, {});
            return this.orderService.createOrder(this.constructData(payload)).pipe(
              catchError((error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.messages || error.error, life: 3000 });
                return of(error);
              }),
            );
          })
        ),
        size: 'small'
      },
    ]
  };

  orders: any[] = [];

  filter$: BehaviorSubject<any> = new BehaviorSubject({});

  changeOrderStatus$: Subject<any> = new Subject<any>();

  constructor(private dialogService: DialogService, private orderService: OrderService, private utilityService: UtilityService, private messageService: MessageService) {
    this.changeOrderStatus$.pipe(
      switchMap(({ orderId, status }: any) => {
        const updatedOrder = this.orders.find(({ id }) => id === orderId);
        const oldStatus = updatedOrder.status;
        updatedOrder.status = status;

        return this.orderService.updateStatus(orderId, status).pipe(
          tap(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: `Update status for order #${orderId} successfully!`, life: 3000 });
          }),
          catchError(() => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please try again!', life: 3000 });
            updatedOrder.status = oldStatus;
            return of({});
          }),
        )
      })
    ).subscribe(() => {
      const filterValue = this.filter$.getValue();
      this.filter$.next({
        ...filterValue,
        conditions: {}
      });
    });

    const [addButton] = this.toolbarConfigs.leftButtons!;
    addButton.event.subscribe();

    const [, syncButton] = this.toolbarConfigs.rightButtons!;
    const toogleSyncButtonState = (isSyncing: boolean) => {
      if (isSyncing) {
        syncButton.icon += ' pi-spin';
        syncButton.disabled = true;
        return;
      }

      syncButton.icon = syncButton.icon.replace(/\spi-spin/g, '');
      syncButton.disabled = false;
    }

    this.subscriptions.add(
      combineLatest([
        this.orderService.getOrderInPriorityAfter(),
        merge(
          this.filter$,
          syncButton.event.pipe(
            switchMap(() => this.filter$)
          ))
      ]).pipe(
        tap(() => {
          toogleSyncButtonState(true);
        }),
        switchMap(([orderInPriorityAfter, filter]) => combineLatest([of(orderInPriorityAfter), this.orderService.getOrdersByFilter(filter).pipe(
          catchError((err) => {
            this.messageService.add({ severity: 'error', summary: err.message, detail: 'Please try again!', life: 3000 });
            return of({ data: [], totalRecords: 0 });
          })
        )])),
        tap(() => {
          toogleSyncButtonState(false);
        }),
        distinctUntilChanged((prev, cur) => JSON.stringify(prev) === JSON.stringify(cur)),
      ).subscribe(([orderInPriorityAfter, { data, totalRecords }]: any) => {
        this.totalRecords = totalRecords;
        this.orders = data.map(({ created_time, modified_time, order_variants, id, ...res }: any) => {
          const orderVariants = order_variants.map(({ print_files, id: order_variant_id, ...item }: any) => ({
            ...item,
            id: order_variant_id,
            print_files
          }));
          return {
            ...res,
            id,
            created_time,
            modified_time,
            order_variants: orderVariants,
            shouldHighlight: moment.duration(moment(new Date()).diff(moment(created_time))).as('hours') > orderInPriorityAfter &&
              ['Shipped', 'Cancelled'].every(value => res.status !== value)
          };
        });
      }));

    combineLatest([
      this.orderService.getOrderStatusConfigs(),
      this.orderService.getOrdersByStatus(),
      this.orderService.getStoreAndSellerFilterOptions()
    ]).subscribe(([configs, totalOrdersByStatus, { stores: storeOptions, sellers: sellerOptions }]: any) => {
      const translateToOptions = (
        (value: any) => (optionKey: string) => value.map((status: string) => ({
          [optionKey]: status, value: status
        }))
      )(configs);

      const views = translateToOptions('name').map((view: any) => {
        const { count } = totalOrdersByStatus.find(({ status }: any) => status === view.name) || { count: 0 };
        return {
          ...view,
          count
        }
      });
      this.viewConfigs.views = views;

      const statusOptions = translateToOptions('status');
      (this.fulfillStatusFilter as any).options = [
        {
          status: '-', value: undefined
        },
        ...statusOptions
      ];

      (this.sellerFilter as any).options = [
        {
          seller: '-', value: undefined
        },
        ...sellerOptions
      ];

      (this.storeFilter as any).options = [
        {
          store: '-', value: undefined
        },
        ...storeOptions
      ];
    });

    this.orderService.getVariants().subscribe((variants) => {
      const { leftSide } = this.listSectionConfig;
      leftSide.filter(({ key }) => [
        'style-color-size'
      ].includes(key)).forEach((control: any) => {
        const key = control.key;
        const options = variants.map((variant: any) => {
          const { style, color, size, id } = variant;
          return {
            [key]: `Style: ${style}, Color: ${color}, Size: ${size}`,
            value: id,
            search: `${style} ${color} ${size}`
          };
        }).sort((a: any, b: any) => a[key].localeCompare(b[key]));
        control['options'] = options;
      });

      const variantControl = leftSide.find(({ key }) => key === 'variant');
      variantControl!.data = variants;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onEditOrder(order: any) {
    console.log(order);

    const lookupValue = (side: any[]) => side.map((field: any) => {
      const value = order[field.key];
      return {
        ...field,
        value
      };
    });

    const addValueForFields = (section: any) => {
      const { leftSide, rightSide } = section;
      const newLeftSide = lookupValue(leftSide);
      const newRightSide = lookupValue(rightSide);
      return {
        ...section,
        leftSide: newLeftSide,
        rightSide: newRightSide
      }
    };

    const [shippingInfoSection, ...sectionConfigsForUpdate] = this.sectionConfigs.map(addValueForFields);
    const listSectionConfigForUpdate = order[this.listSectionConfig.key].map(({
      created_time, modified_time, id, print_files, variant, ...item
    }: any) => ({
      ...item,
      ...print_files.reduce((obj: any, cur: any) => {
        return {
          ...obj,
          [cur.name]: cur.url
        }
      }, {}),
      variant: variant.id,
      'style-color-size': variant.id
    }));

    const [cancelButton, saveButton] = this.toolbarForSave.rightButtons!;

    const toolbarForSave = {
      ...this.toolbarForSave,
      rightButtons: [
        cancelButton,
        {
          ...saveButton,
          httpRequest: (data: any) => of(data).pipe(
            switchMap(({ order_variants, priority, ...data }: any) => {
              return this.orderService.updateOrder({ ...data, id: order.id, tracking_number: data.tracking_number?.replace(/\s/g, '') }).pipe(
                catchError((error) => {
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Check your input again!', life: 3000 });
                  return of(error);
                }),
              );
            }))
        }
      ]
    };

    this.ref = this.dialogService.open(FormComponent, {
      header: 'Update Order',
      width: '85vw',
      contentStyle: { overflow: 'auto' },
      data: {
        toolbarForSave,
        timelines: order.timelines.sort((a: any, b: any) => moment(a.modified_time).diff(b.modified_time)).map((item: any) => ({
          status: item.status,
          date: moment(item.modified_time).format('MMM D, YYYY, LT'),
          user: `${item.modified_by.firstName} ${item.modified_by.lastName} (${item.modified_by.userName})`
        })),
        sectionConfigs: [{
          ...shippingInfoSection,
          section: `${shippingInfoSection.section} (Order ID: #${order.id})`
        }, ...sectionConfigsForUpdate],
        listSectionConfig: {
          ...this.listSectionConfig,
          common: {},
          disabled: true,
          [this.listSectionConfig.key]: listSectionConfigForUpdate
        },
      }
    });

    this.ref?.onClose.pipe(
      filter(isAccepted => isAccepted)
    ).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Your order is updated', life: 3000 });

        const [, syncButton] = this.toolbarConfigs.rightButtons!;
        (syncButton.event as EventEmitter<any>).emit();
      }
    );
  }

  pageChange(event: any) {
    const filterValue = this.filter$.getValue();
    this.filter$.next({
      ...filterValue,
      skip: event.first,
      take: event.rows,
    });
  }

  quantityChange(event: any) {
    this.pageChange(event);
  }

  sorting(event: any) {
    const filterValue = this.filter$.getValue();
    this.filter$.next({
      ...filterValue,
      skip: 0,
      sort: event.order === 1 ? 'ASC' : 'DESC'
    });
  }

  readonly constructData = ({ order_variants, tracking_number, ...input }: any): Order => {
    return {
      ...input,
      tracking_number: tracking_number ?? tracking_number?.replace(/\s/g, ''),
      order_variants: order_variants.map((item: any) => ({
        product_name: item.product_name,
        variant: item.variant,
        quantity: +item.quantity,
        mockup_front: item.mockup_front || undefined,
        mockup_back: item.mockup_back || undefined,
        print_files: ['back', 'front', 'sleeve_left', 'sleeve_right'].filter((file: string) => item[file]).map((file: string) => ({
          name: file,
          url: item[file]
        }))
      })),
    };
  };

  viewConvertedLabel(order: any) {
    this.utilityService.lookupReplacementUrl(order.shipping_label).pipe(
      tap(fn => {
        this.ref = this.dialogService.open(PdfViewerComponent, {
          header: 'Print Label',
          width: '85vw',
          contentStyle: { overflow: 'auto' },
          data: {
            order,
            url: `${environment.apiUrl}/order/get-file/${encodeURIComponent(fn(order.shipping_label))}`
          }
        });
      })
    ).subscribe();
  }
}
