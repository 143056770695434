<div [style.width.px]="width" [style.height.px]="height">
  @if (!showQr) {
  <p-image
    #image
    [previewImageSrc]="imageSrc"
    [src]="imageSrc"
    [preview]="true"
    *ngIf="!isImageLoading; else noImageFound"
    [imageStyle]="{ width: '100%', height: '100%;' }"
  >
    <ng-template pTemplate="closeicon">
      <span class="text-3xl" (click)="originalImage.click()">View Original Image</span>
    </ng-template>
  </p-image>

  } @else if (!isImageLoading) {
  <p-button [link]="true" icon="pi pi-qrcode text-xl" pTooltip="{{ text }}" tooltipPosition="top" (click)="image.previewButton!.nativeElement.click()" />
  <p-image [previewImageSrc]="imageSrc" [preview]="true" #image>
    <ng-template pTemplate="closeicon">
      <span class="text-3xl" (click)="originalImage.click()">View Original Image</span>
    </ng-template>
  </p-image>
  }
  <a class="hidden" href="{{ originalSrc }}" target="_blank" #originalImage></a>
  <ng-template #noImageFound>
    <p-skeleton width="100%" height="100%" styleClass="mb-2" />
  </ng-template>
</div>
