import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  selector: 'app-wallet-toolbar',
  standalone: true,
  imports: [
    TabMenuModule,
  ],
  templateUrl: './wallet-toolbar.component.html',
  styleUrl: './wallet-toolbar.component.css'
})
export class WalletToolbarComponent {
  items: MenuItem[] = [];
  constructor(private router: Router) {}

  ngOnInit() {
    this.items = [
      { label: 'Wallet Dashboard', icon: 'pi pi-fw pi-warehouse', routerLink: '/wallet/dashboard' },
      { label: 'My Wallet Activity', icon: 'pi pi-fw pi-calendar', routerLink: '/wallet/my-wallet' },
    ];
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }
}
