import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SharePrimeNGModule } from '../share-primeng.module';
import { MyShopService } from '../services/myshop.service';
import { query } from 'express';
import { AuthenticationService } from '../services/authentication.service';
import { ConversationService } from '../services/conversation.service';

@Component({
  selector: 'app-my-shops',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharePrimeNGModule],
  providers: [MessageService],
  templateUrl: './my-shops.component.html',
  styleUrl: './my-shops.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MyShopsComponent {
  activeIndex: number = 0;
  orderCollections: any = [];
  totalCollections = 0;

  constructor(private authenticationService: AuthenticationService, private messageService: MessageService, private myShopService: MyShopService) {}

  ngOnInit(): void {
    this.getMyOrderCollections();
  }

  getMyOrderCollections() {
    this.myShopService.getMyOrderCollections().subscribe({
      next: (response) => {
        this.orderCollections = response.data;
        this.totalCollections = response.total;
      },
      error: (error) => {
        this.sendError('Load order collections!', `Unable to perform action: ${error}`);
      },
    });
  }

  processIncompleteCollections() {
    this.myShopService.processIncompleteCollections().subscribe({
      next: (response) => {
        this.getMyOrderCollections();
      },
      error: (error) => {
        this.sendError('Process Incomplete Order Collections!', `Unable to perform action: ${error}`);
      },
    });
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }

  checkConnectionTikTok() {
    this.myShopService.checkConnectionTikTok().subscribe({
      next: () => {},
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: `Unable to perform the action: ${err}`,
        });
      },
    });
  }
}
