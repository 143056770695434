import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs';

import { FreeTextEditorAnnotation, NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import moment from 'moment';
import { AuthenticationService } from '../services/authentication.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pdf-viewer',
  standalone: true,
  imports: [
    NgxExtendedPdfViewerModule,
  ],
  templateUrl: './pdf-viewer.component.html',
  styleUrl: './pdf-viewer.component.css',
})
export class PdfViewerComponent implements OnDestroy {
  pdfReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  order: any;
  base64Src: any;
  bearerToken: string;

  constructor(private config: DynamicDialogConfig, private pdfViewerService: NgxExtendedPdfViewerService, private authService: AuthenticationService, private http: HttpClient) {
    this.bearerToken = `Bearer ${this.authService.userSession?.access_token}`;

    this.order = this.config.data.order;
    this.http.get(this.config.data.url, { responseType: 'arraybuffer' }).pipe(
      map((arrayBuffer: ArrayBuffer) => {
        let binary = '';
        const bytes = new Uint8Array(arrayBuffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      })
    ).subscribe((base64) => {
      this.base64Src = base64;
    });

    console.log(this.order)

    this.pdfReady$.pipe(
      filter(value => value),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.addOrderDetail();
    });
  }

  ngOnDestroy(): void {
    document.querySelector('body > #printContainer')?.remove();
    document.querySelector('body > #fileInput')?.remove();
  }

  addOrderDetail = () => {
    const getValue = () => [`${moment(this.order.created_time).format('MM_DD')} - #${this.order.id}`, ...this.order.order_variants.map(({ variant, quantity }: any) => `${variant.style} - ${variant.size} x${quantity}`)].join('\n');
    const textEditorAnnotation: FreeTextEditorAnnotation = {
      annotationType: 3,
      fontSize: 10,
      value: getValue(),
      pageIndex: 0,
      color: [0, 0, 0], // RGB: black
      rect: [
        8, 9.5, 68, 56 // magic number: coordinate in viewpoint
      ],
      rotation: 0
    };

    this.pdfViewerService.addEditorAnnotation(textEditorAnnotation);
  }

  onPagesLoaded(event: any) {
  }

  onEvent(type: string, event: any) {
    const actions: any = {
      'annotationEditorLayerRendered': () => this.pdfReady$.next(true)
    }
    actions[type] && actions[type]();
  }
}
