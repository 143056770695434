<p-toolbar>
  <div class="p-toolbar-group-start"></div>
  <div class="p-toolbar-group-center">
    <strong class="mr-2">Action: </strong>
    <p-dropdown [options]="searchOptions.Actions" [(ngModel)]="selectedSearch.action" filter="true" (onChange)="onSearch()" [style]="{ width: '200px' }" />
    <strong class="mr-2 ml-2">Entity: </strong>
    <p-dropdown [options]="searchOptions.Entities" [(ngModel)]="selectedSearch.entity" filter="true" (onChange)="onSearch()" [style]="{ width: '200px' }" />
    <strong class="mr-2 ml-2">Created by: </strong>
    <p-dropdown [options]="searchOptions.Users" [(ngModel)]="selectedSearch.user" filter="true" (onChange)="onSearch()" [style]="{ width: '200px' }" />
    <div class="p-field-row">
      <p-button icon="pi pi-search-plus" label="Search" severity="success" class="p-button-success mr-2 ml-2" (click)="onSearch()" />
      <p-button icon="pi pi-eraser" label="Clear" severity="success" (click)="onClearSearch()" class="p-button-success" class="mr-2" />
    </div>
  </div>
  <div class="p-toolbar-group-end"></div>
</p-toolbar>
<p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '3px' }" />
<div class="card">
  <p-table [value]="auditTrails.data" dataKey="id" [scrollable]="true" scrollHeight="flex">
    <!-- Table Header -->
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id" style="text-align: center">ID</th>
        <th pSortableColumn="action">Action <p-sortIcon field="action"></p-sortIcon></th>
        <th pSortableColumn="entityName">Target Entity <p-sortIcon field="entityName"></p-sortIcon></th>
        <th pSortableColumn="oldValue">Old Value</th>
        <th pSortableColumn="newValue">New Value</th>
        <th pSortableColumn="created_by">Created By</th>
        <th pSortableColumn="created_by">Created Time</th>
      </tr>
    </ng-template>

    <!-- Table Body -->
    <ng-template pTemplate="body" let-i>
      <tr>
        <td style="text-align: right">{{ i.id }}</td>
        <td>{{ i.action }}</td>
        <td>{{ i.entityName }}</td>
        <td>{{ i.oldValue }}</td>
        <td class="text-overflow" [pTooltip]="i.newValue" tooltipPosition="top">{{ i.newValue }}</td>
        <td>{{ i.created_by }}</td>
        <td>{{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    #paginator
    [rows]="defaultPageLimit"
    [totalRecords]="auditTrails.total"
    [rowsPerPageOptions]="[defaultPageLimit, 50, 100]"
    [showFirstLastIcon]="true"
    (onPageChange)="onPageChangeAuditTrail($event)"
    currentPageReportTemplate="Showing {first} to {last} of {{ auditTrails.total }}"
    [showCurrentPageReport]="true"
  ></p-paginator>
</div>
<p-toast></p-toast>
