import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FooterComponent } from '../../shared-ui/footer/footer.component';
import { HeaderComponent } from '../../shared-ui/header/header.component';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SharePrimeNGModule } from '../../share-primeng.module';

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-api-integration',
  standalone: true,
  imports: [SharePrimeNGModule, HeaderComponent, FooterComponent],
  templateUrl: './api-integration.component.html',
  styleUrl: './api-integration.component.css',
})
export class ApiIntegrationComponent implements OnInit {
  apiSpecUrl: SafeResourceUrl | undefined;
  isLoading = true;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.apiSpecUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.apiUrl}/`);
  }

  onApiPageLoaded() {
    this.isLoading = false;
  }
}
