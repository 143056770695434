import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from '../../shared-ui/footer/footer.component';
import { HeaderComponent } from '../../shared-ui/header/header.component';
import { ToastModule } from "primeng/toast"
import { PasswordModule } from "primeng/password"
import { MessageService } from 'primeng/api'
import { ButtonModule } from "primeng/button"
import { PanelModule } from "primeng/panel"
import { CardModule } from "primeng/card"
import { LoginService } from './login.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    PanelModule,
    CommonModule,
    ReactiveFormsModule,
    ToastModule,
    PasswordModule,
    ButtonModule,
    CardModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {


  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authenticationService: AuthenticationService
  ) { }
  ngOnInit(): void {
    this.authenticationService.logout();
  }

  loginForm = this.fb.group({
    userName: ['', [Validators.required]],
    password: ['', Validators.required]
  }, {})

  get userName() {
    return this.loginForm.controls['userName'];
  }

  get password() {
    return this.loginForm.controls['password'];
  }

  submitDetails() {
    const postData = { ...this.loginForm.value };
    var userName = postData.userName ?? "";
    var password = postData.password ?? "";
    console.log(this.loginForm.value);
    this.authenticationService.signIn(userName, password).subscribe({
      next: (user) => {
        const rootUrl = location.origin;
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
        const urlObj = new URL(`${rootUrl}${returnUrl}`);
        const queryParams = new URLSearchParams(urlObj.search);
        const params: any = {};
        queryParams.forEach((value, key) => {
          params[key] = value;
        });
        this.router.navigate([urlObj.pathname],{queryParams : params});
      },
      error: (e) => {
        const message = e.error.message || e.message;
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: message,
          sticky: true
        })
      },
      complete: () => {
      }
    })
  }
}
