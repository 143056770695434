import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { ProductToolbarComponent } from '../../shared-ui/product-toolbar/product-toolbar.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProductService } from '../../services/product.service';
import { PriceTier } from '../../models/product.model';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-pricing-tiers',
  standalone: true,
  imports: [CommonModule, SharePrimeNGModule, ProductToolbarComponent],
  providers: [ConfirmationService, MessageService],
  templateUrl: './pricing-tiers.component.html',
  styleUrl: './pricing-tiers.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PricingTiersComponent {
  pricingTiers: any[] = [];
  showNewPriceTierDialog = false;
  newPriceTier: PriceTier = {};
  isLoading = false;

  constructor(private productService: ProductService, private confirmationService: ConfirmationService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.getPriceTiers();
  }

  getPriceTiers() {
    this.isLoading = true;
    this.productService.findPricingTiers()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe({
      next: (response) => {
        this.pricingTiers = response;
      },
      error: (err) => {
        this.sendError('Error!', `Error loading pricing tiers: ${err}`);
      },
      complete: () => {},
    });
  }

  onShowNewPriceTierDialog() {
    this.showNewPriceTierDialog = true;
  }

  onCreateNewPriceTier() {
    if (!this.newPriceTier.name || !this.newPriceTier.description) {
      this.sendError('Error!', `Please provide the tier name and description before saving.`);
      return;
    }

    this.productService.addNewPriceTier(this.newPriceTier).subscribe({
      next: () => {
        this.showNewPriceTierDialog = false;
        this.getPriceTiers();
        this.sendInfo('New Price Tier is created!', `The Tier ${this.newPriceTier.name} and initial fees have been added successfully.`);
      },
      error: (err) => {
        this.sendError(err.error, `Error deleting pricing tier: ${err.messages}`);
      },
      complete: () => {},
    });
  }

  onDeleteTier(tier: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete the price tier <strong>${tier.name}</strong>?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.productService.deletePriceTier(tier.id).subscribe({
          next: () => {
            this.getPriceTiers();
            this.sendInfo('Price Tier is deleted!', `The Tier ${tier.name} has been deleted successfully.`);
          },
          error: (err) => {
            this.sendError(err.error, `Error deleting pricing tier: ${err.messages}`);
          },
          complete: () => {},
        });
      },
      reject: () => {},
    });
  }

  sendInfo(summary: string, detail: string) {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
