<p-toolbar>
  <p-button icon="pi pi-plus" label="New Store" severity="success" class="p-button-success" (click)="showNewStoreDialog = true" />
</p-toolbar>
<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '30vw' }"></p-confirmDialog>

<p-dialog header="Create New Store" [modal]="true" [(visible)]="showNewStoreDialog">
  <div class="flex align-items-center gap-3 mb-3">
    <label for="name" class="font-semibold w-8rem">Store Name</label>
    <input id="length" pInputText [(ngModel)]="newStore.name" [style]="{ 'min-width': '300px' }" />
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="description" class="font-semibold w-8rem">Description</label>
    <input id="length" pInputText [(ngModel)]="newStore.description" [style]="{ 'min-width': '300px' }" />
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label class="font-semibold w-8rem"></label>
    <p-button icon="pi pi-save" label="Save" (onClick)="saveNewStore()"></p-button>
    <p-button icon="pi pi-times-circle" label="Cancel" severity="secondary" (onClick)="showNewStoreDialog = false"></p-button>
  </div>
</p-dialog>

<p-dialog header="Update Store Details" [modal]="true" [(visible)]="showUpdateStoreDialog">
  <div class="flex align-items-center gap-3 mb-3">
    <label for="name" class="font-semibold w-8rem">Store Name</label>
    <input id="length" pInputText [(ngModel)]="updateStore.name" [style]="{ 'min-width': '300px' }" />
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="description" class="font-semibold w-8rem">Description</label>
    <input id="length" pInputText [(ngModel)]="updateStore.description" [style]="{ 'min-width': '300px' }" />
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label class="font-semibold w-8rem"></label>
    <p-button icon="pi pi-save" label="Save" (onClick)="saveStoreDetail()"></p-button>
    <p-button icon="pi pi-times-circle" label="Cancel" severity="secondary" (onClick)="showUpdateStoreDialog = false"></p-button>
  </div>
</p-dialog>

<p-dialog header="Update Store's Price Tier" [modal]="true" [(visible)]="showUpdatePriceTierDialog">
  <div class="flex align-items-center gap-3 mb-3">
    <label for="tier" class="font-semibold w-8rem">Applied Price Tier</label>
    <p-dropdown [options]="pricingTiers" optionLabel="name" optionValue="id" [(ngModel)]="selectedTierId" [style]="{ 'min-width': '250px' }"></p-dropdown>
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="name" class="font-semibold w-8rem">Store Name</label>
    {{ updateStoreTier.name }}
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="description" class="font-semibold w-8rem">Description</label>
    {{ updateStoreTier.description }}
  </div>
  <div class="flex align-items-center gap-3 mb-3" style="height: 50px"></div>
  <div class="flex align-items-center gap-3 mb-3">
    <label class="font-semibold w-8rem"></label>
    <p-button icon="pi pi-save" label="Save" (onClick)="saveStoreTier()"></p-button>
    <p-button icon="pi pi-times-circle" label="Cancel" severity="secondary" (onClick)="showUpdatePriceTierDialog = false"></p-button>
  </div>
</p-dialog>

<p-dialog header="Update users for store: {{ updateStoreUser.name }}" [modal]="true" [(visible)]="showUpdateStoreUsersDialog">
  <table>
    <tr>
      <td>
        <div>Selected Users:</div>
        <p-listbox
          [multiple]="true"
          [options]="selectedUsers"
          [(ngModel)]="hlSelectedIds"
          optionLabel="username"
          optionValue="id"
          [filter]="true"
          [style]="{ 'min-width': '300px', height: '450px' }"
        >
          <ng-template pTemplate="item" let-i>{{ i.username }} ({{ i.firstname }} {{ i.lastname }} - {{ i.rolename }})</ng-template>
        </p-listbox>
      </td>
      <td>
        <div>
          <p-button label="Add" icon="pi pi-angle-left" (click)="addUserToStore()" [disabled]="!hlAvailableIds.length" [style]="{ width: '100px' }"></p-button>
          <br /><br />
          <p-button
            label="Remove"
            icon="pi pi-angle-right"
            (click)="removeUserFromStore()"
            [disabled]="!hlSelectedIds.length"
            [style]="{ width: '100px' }"
          ></p-button>
        </div>
      </td>
      <td>
        <div>Users are NOT in the store:</div>
        <p-listbox
          [multiple]="true"
          [options]="availableUsers"
          [(ngModel)]="hlAvailableIds"
          optionLabel="username"
          optionValue="id"
          [filter]="true"
          [style]="{ 'min-width': '300px', height: '450px' }"
        >
          <ng-template pTemplate="item" let-i>{{ i.username }} ({{ i.firstname }} {{ i.lastname }} - {{ i.rolename }})</ng-template>
        </p-listbox>
      </td>
    </tr>
  </table>
  <div class="p-grid p-justify-end p-mt-3 gap-3 mb-3"></div>
  <div>
    <p-button icon="pi pi-save" label="Save" (onClick)="saveStoreUsers()" class="mr-2"></p-button>
    <p-button icon="pi pi-times-circle" label="Cancel" severity="secondary" (onClick)="showUpdateStoreUsersDialog = false"></p-button>
  </div>
</p-dialog>

<div class="card">
  <p-table [value]="stores" dataKey="id" [scrollable]="true" scrollHeight="flex">
    <!-- Table Header -->
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id">ID</th>
        <th pSortableColumn="name">Store Name</th>
        <th>Description</th>
        <th pSortableColumn="userCount">Number of Users</th>
        <th pSortableColumn="tier?.name">Applied Price Tier</th>
        <th pSortableColumn="created_time">Created Time</th>
        <th pSortableColumn="modified_time">Modified Time</th>
        <th style="text-align: center">Action</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-i>
      <tr class="data-row">
        <td>{{ i.id }}</td>
        <td>{{ i.name }}</td>
        <td>{{ i.description }}</td>
        <td>
          <div style="display: flex; align-items: center">
            <button
              pButton
              type="button"
              icon="pi pi-users"
              pTooltip="Update users"
              tooltipPosition="top"
              class="p-button-rounded p-button-success p-button-text"
              (click)="openUpdateStoreUsersDialog(i)"
            ></button>
            {{ i.userCount }}
          </div>
        </td>
        <td>
          <div style="display: flex; align-items: center">
            <button
              pButton
              type="button"
              icon="pi pi-database"
              pTooltip="Update price tier"
              tooltipPosition="top"
              class="p-button-rounded p-button-success p-button-text"
              (click)="openUpdateStoreTierDialog(i)"
            ></button>
            {{ i.tier?.name }}
          </div>
        </td>
        <td>{{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td>{{ i.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td style="text-align: center">
          <button
            pButton
            type="button"
            icon="pi pi-pencil"
            pTooltip="Update store details"
            tooltipPosition="top"
            class="p-button-rounded p-button-success p-button-text"
            (click)="openUpdateStoreDetailDialog(i)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-database"
            pTooltip="Update price tier"
            tooltipPosition="top"
            class="p-button-rounded p-button-success p-button-text"
            (click)="openUpdateStoreTierDialog(i)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-users"
            pTooltip="Update users"
            tooltipPosition="top"
            class="p-button-rounded p-button-success p-button-text"
            (click)="openUpdateStoreUsersDialog(i)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            pTooltip="Delete"
            tooltipPosition="top"
            severity="danger"
            [outlined]="true"
            class="p-button-rounded p-button-danger p-button-text"
            (click)="deleteStore(i)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
