import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const quantityGreaterThanValidator = (value: number, listName: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        if (control.value.length < value) {
            return { message: `Need at least ${value} item${value > 1 ? 's' : ''} in the ${listName}` };
        }

        return {};
    };
};

export const atLeastOneIn = (properties: string[] = []): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        const formGroup = control as FormGroup<any>;
        if (properties.every(prop => !formGroup?.get(prop)?.value)) {
            return { message: `Need at least ${properties.join(' or ')} has value` }
        }

        return {};
    };
};

export const notEqualTo = (value: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors => {
        if (control.value?.toLowerCase().includes(value)) {
            return { message: `Is pending` };
        }

        return {};
    };
}