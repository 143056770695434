import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QualityControlService } from './quality.control.service';
import { SharePrimeNGModule } from '../share-primeng.module';
import * as _ from 'underscore';
import { DividerModule } from 'primeng/divider';
import { AuthenticationService } from '../services/authentication.service';
import { ImageComponent } from '../shared-ui/common/image/image.component';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '../../environments/environment';
import { Conversation } from '../models/conversation.model';
import { MessageService } from 'primeng/api';
import { EditorModule } from 'primeng/editor';
import { ConversationService } from '../services/conversation.service';
import { error } from 'console';

@Component({
  selector: 'app-quality-control',
  standalone: true,
  imports: [SharePrimeNGModule, EditorModule, DividerModule, ImageComponent, PdfViewerComponent],
  providers: [DialogService, MessageService],
  templateUrl: './quality-control.component.html',
  styleUrl: './quality-control.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QualityControlComponent {
  ref: DynamicDialogRef | undefined;
  orderId: number = 0;
  order!: any;
  commentCount = 0;
  sellerInfo = '';
  staffInfo = '';
  conversation: Conversation = {
    id: 0,
    comments: [],
  };
  showConversationDialog = false;
  commentEditor = '';
  loginUserName: any;

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private qualityControlService: QualityControlService,
    private authService: AuthenticationService,
    private dialogService: DialogService,
    private conversationService: ConversationService
  ) {
    const orderId = this.route.snapshot.queryParams['id'];
    this.orderId = orderId;
    this.order = {
      id: 0,
      order_variants: [],
      seller_name: '',
      shipping_label: '',
      status: '',
      ticket_id: '',
      tracking_number: '',
      verified_by: '',
      user: '',
      totalItem: 0,
      created_time: new Date(),
    };
    this.getOrderDetail(orderId);

    authenticationService.user.subscribe((user) => {
      this.loginUserName = user;
    });
    this.loginUserName = this.authenticationService.userSession?.userName;
  }

  getOrderDetail(orderId: number) {
    this.qualityControlService.getOrderDetail(orderId).subscribe({
      next: (response: any) => {
        this.order = JSON.parse(JSON.stringify(response));
        this.order.user = this.authService?.userSession?.userName;
        this.order.totalItem = _.reduce(this.order.order_variants, (sum, item) => sum + item.quantity, 0);

        this.getCommentCount(orderId);
        this.getSellerStaffDetail(this.order.created_by, this.order.verified_by);
      },
      error: (error) => {
        this.sendError('Get order detail!', `Failed to perform the action: ${error}`);
      },
    });
  }

  getCommentCount(orderId: number) {
    this.qualityControlService.countConversationComment(orderId).subscribe({
      next: (response: any) => {
        this.commentCount = response;
      },
      error: (error) => {
        this.sendError('Count conversation comments!', `Failed to perform the action: ${error}`);
      },
    });
  }

  getSellerStaffDetail(sellerId: number, staffName: string) {
    if (sellerId) {
      this.qualityControlService.getSellerDetail(sellerId).subscribe((response: any) => {
        this.sellerInfo = `${response.username} (${response.rolename})`;
      });
    }
    if (staffName) {
      this.qualityControlService.getStaffDetail(staffName).subscribe((response: any) => {
        this.staffInfo = `${response.username} (${response.rolename})`;
      });
    }
  }

  shipOrder() {
    const order = this.order;
    this.qualityControlService.shipOrder(this.orderId, this.loginUserName).subscribe({
      next: (data) => {
        this.printOrder();
        this.getOrderDetail(this.orderId);
      },
      error: (error) => {
        this.sendError('Update order status!', `Failed to perform the action: ${error}`);
      },
    });
  }

  printOrder() {
    const order = this.order;
    this.ref = this.dialogService.open(PdfViewerComponent, {
      header: 'Print Label',
      width: '85vw',
      contentStyle: { overflow: 'auto' },
      data: {
        order,
        url: `${environment.apiUrl}/order/get-file/${encodeURIComponent(order.shipping_label)}`,
      },
    });
  }

  openConversationDialog() {
    this.showConversationDialog = true;
    this.qualityControlService.getConversation(this.order.id).subscribe((response: any) => {
      this.conversation = response;
    });
  }

  sendNewConversationComment() {
    const c = this.conversation;
    this.conversationService
      .addComment({
        id: c.id,
        first_comment: c.comments?.length === 0 ? this.extractTextFromHTML(this.commentEditor) : '',
        last_comment: c.comments?.length > 0 ? this.extractTextFromHTML(this.commentEditor) : '',
        comment: this.commentEditor,
        created_by: this.loginUserName,
      })
      .subscribe({
        next: (addedComment) => {
          c.comments.push(addedComment);
          this.commentCount = c.comments.length;
        },
        error: (err) => {
          this.sendError('Error!', `Failed to add comment due to an error: ${err}`);
        },
      });

    this.commentEditor = '';
  }

  extractTextFromHTML(html: string, maxLength: number = 150): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const textContent = doc.body.textContent || '';
    if (textContent.length > maxLength) {
      return textContent.slice(0, maxLength);
    }
    return textContent;
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
