import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, map, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { OrderCollection } from '../models/mshop.model';

@Injectable({
  providedIn: 'root',
})
export class MyShopService {
  private apiUrl = `${environment.apiUrl}/myshop`;

  constructor(private http: HttpClient) { }

  checkConnectionTikTok(): Observable<any> {
    return this.http.get(`${this.apiUrl}/checkConnectionTikTok`).pipe(
      map((response) => response),
    );
  }

  getMyOrderCollections(): Observable<{ total: number; data: OrderCollection[] }> {
    return this.http.get(`${this.apiUrl}/get-my-order-collections`).pipe(
      map((response: any) => response),
    );
  }

  processIncompleteCollections(): Observable<any> {
    return this.http.get(`${this.apiUrl}/process-incomplete-collections`).pipe(
      map((response) => response),
    );
  }
}
