import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuItem } from 'primeng/api';

import { SharePrimeNGModule } from '../../share-primeng.module';
import { CommonService } from '../../services/common.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { LoadingOverlayComponent } from '../../shared-ui/common/loading-overlay/loading-overlay.component';
import { BehaviorSubject, filter } from 'rxjs';
import _, { has } from 'underscore';

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css',
  imports: [CommonModule, SharePrimeNGModule, LoadingOverlayComponent],
  host: { ngSkipHydration: 'true' },
})
export class LayoutComponent {
  leftSideNavState = signal('');
  leftSideNav: MenuItem[] = [];
  sidebarVisible: boolean = false;
  leftNavVisible: boolean = true;
  isMobile = false;
  data = {};
  options = {};

  profileMenu: MenuItem[] = [
    {
      label: 'Profile',
      icon: 'pi pi-user',
      command: () => this.navigate('/user-profile'),
    },
    {
      label: 'Setting',
      icon: 'pi pi-cog',
      visible: false,
      command: () => this.navigate('/settings'),
    },
    {
      separator: true,
    },
    {
      label: 'Logout',
      icon: 'pi pi-power-off',
      command: () => this.logout(),
    },
  ];

  isLogged: boolean = false;
  username: string = '';

  isLoading$: BehaviorSubject<boolean>;

  title: string = '';

  constructor(private commonService: CommonService, private router: Router, private authenticationService: AuthenticationService) {
    this.isLoading$ = this.commonService.isLoading$;

    authenticationService.user.subscribe((user) => {
      this.setUserName(user);
    });
    this.setUserName(this.authenticationService.userSession);

    this.commonService.isDesktop$.subscribe((isDesktop) => {
      this.isMobile = !isDesktop;
      this.leftNavVisible = isDesktop;
    });

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(({ url }: any) => {
      this.leftSideNavState.set(url);
      this.title = menuItems.find(({ route }) => route === url)?.label || '';
    });
    let menuItems = [
      {
        label: 'Home',
        icon: 'pi pi-home',
        route: '',
        visible: true,
      },
      {
        label: 'Dashboard',
        icon: 'pi pi-calculator',
        route: '/dashboard',
        visible: true,
      },
      {
        label: 'Wallet',
        icon: 'pi pi-wallet',
        route: '/wallet/dashboard',
        visible: true,
      },
      {
        label: 'Orders',
        icon: 'pi pi-list',
        route: '/orders',
        visible: true,
      },
      {
        label: 'Trackings',
        icon: 'pi pi-truck',
        route: '/trackings',
        visible: true,
      },
      {
        label: 'Reports',
        icon: 'pi pi-chart-pie',
        route: '/reports',
        visible: true,
      },
      {
        label: 'Conversations',
        icon: 'pi pi-comments',
        route: '/conversations/dashboard',
        visible: true,
      },
      {
        label: 'Products',
        icon: 'pi pi-warehouse',
        route: '/product/products',
        visible: true,
      },
      {
        label: 'Stores',
        icon: 'pi pi-shop',
        route: '/stores',
        visible: true,
      },
      {
        label: 'My Shops',
        icon: 'pi pi-shop',
        route: '/myshops',
        visible: true,
      },
      {
        label: 'Users',
        icon: 'pi pi-users',
        route: '/users',
        visible: true
      },
      {
        label: 'Settings',
        icon: 'pi pi-cog',
        route: '/settings',
        visible: true,
      },
      {
        label: 'Audit Trails',
        icon: 'pi pi-info-circle',
        route: '/audit-trails',
        visible: true,
      },
    ]

    menuItems = menuItems.map(item => {
      const hasPermission = this.checkPermission(item)
      item.visible = hasPermission;
      return item;
    })

    this.leftSideNav = [
      ...menuItems.map(({ route, ...item }) => ({
        ...item,
        route,
        command: () => {
          this.leftSideNavState.set(route);
          this.navigate(route);
        },
      })),
      {
        separator: true,
      },
    ]
  }


  checkPermission(item: any) {
    if (this.authenticationService.userSession) {
      const permissions: string[] = this.authenticationService.userSession.permissions;
      let hasPermission = false;
      permissions.some((value: any, index) => {
        hasPermission = value.access_paths?.some((p: any) => p == item.route)
        if (hasPermission) {
          return hasPermission;
        }
        return false;
      })
      return hasPermission;
    }
    return false;
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  setUserName(user: any) {
    if (user) {
      this.isLogged = true;
      this.username = user.name;
    } else {
      this.isLogged = false;
      this.username = '';
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }
}
