import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceCatalogComponent } from './front-pages/service-catalog/service-catalog.component';
import { HomeComponent } from './front-pages/home/home.component';
import { LoginComponent } from './front-pages/login/login.component';
import { ApiIntegrationComponent } from './front-pages/api-integration/api-integration.component';
import { OrdersComponent } from './orders/orders.component';
import { ProductsComponent } from './products/products.component';
import { NewProductComponent } from './products/new-product/new-product.component';
import { VariantsComponent } from './products/variants/variants.component';
import { RegisterComponent } from './front-pages/register/register.component';
import { AuthGuard } from './helpers/auth.guard';
import { WalletDashboardComponent } from './wallet/wallet-dashboard/wallet-dashboard.component';
import { MyWalletComponent } from './wallet/my-wallet/my-wallet.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingsComponent } from './settings/settings.component';
import { LayoutComponent } from './layouts/layout/layout.component';
import { FrontLayoutComponent } from './layouts/front-layout/front-layout.component';
import { TrackingsComponent } from './trackings/trackings.component';
import { RolesComponent } from './users-management/roles/roles.component';
import { UsersComponent } from './users-management/users/users.component';
import { PermissionsComponent } from './users-management/permissions/permissions.component';
import { NewUserComponent } from './users-management/new-user/new-user.component';
import { UserActivationComponent } from './users-management/user-activation/user-activation.component';
import { featureFlagGuard } from './helpers/feature.flag.guard';
import { ConversationDashboardComponent } from './conversations/conversation-dashboard/conversation-dashboard.component';
import { PricingTiersComponent } from './products/pricing-tiers/pricing-tiers.component';
import { PricingRulesComponent } from './products/pricing-rules/pricing-rules.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { StoresComponent } from './stores/stores.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { QualityControlComponent } from './quality-control/quality-control.component';
import { MyShopsComponent } from './my-shops/my-shops.component';
import { QCLayoutComponent } from './layouts/qclayout/qclayout.component';
import { ProductionComponent } from './front-pages/production/production.component';
import { AboutUsComponent } from './front-pages/about-us/about-us.component';
import { ContactUsComponent } from './front-pages/contact-us/contact-us.component';
import { ReportComponent } from './report/report.component';

export const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: '',
    component: FrontLayoutComponent,
    children: [
      { path: 'production', component: ProductionComponent },
      { path: 'service-catalog', component: ServiceCatalogComponent },
      { path: 'api-integration', component: ApiIntegrationComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'contact-us', component: ContactUsComponent},
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'activate-user', component: UserActivationComponent },
    ],
  },
  {
    path: '',
    component: QCLayoutComponent,
    children: [
      { path: 'qc/order', component: QualityControlComponent },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent},
      { path: 'orders', component: OrdersComponent },
      { path: 'users', component: UsersComponent, canActivate : [AuthGuard]},
      { path: 'user-profile', component: UserProfileComponent, canActivate : [AuthGuard]},
      { path: 'new-user', component: NewUserComponent },
      { path: 'edit-user/:id', component: NewUserComponent },
      { path: 'roles', component: RolesComponent },
      { path: 'permissions', component: PermissionsComponent },
      { path: 'trackings', component: TrackingsComponent },
      { path: 'product/products', component: ProductsComponent },
      { path: 'product/new-product', component: NewProductComponent },
      { path: 'product/edit-product/:id', component: NewProductComponent },
      { path: 'product/variants', component: VariantsComponent },
      { path: 'product/pricing-tiers', component: PricingTiersComponent },
      { path: 'product/pricing-rules', component: PricingRulesComponent },
      { path: 'stores', component: StoresComponent },
      { path: 'myshops', component: MyShopsComponent },
      { path: 'wallet/dashboard', component: WalletDashboardComponent },
      { path: 'wallet/my-wallet', component: MyWalletComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'conversations/dashboard', component: ConversationDashboardComponent },
      { path: 'conversations/order/:id', component: ConversationDashboardComponent },
      { path: 'audit-trails', component: AuditTrailComponent },
      { path: 'reports', component: ReportComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
