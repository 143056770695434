<div class="relative overflow-hidden flex flex-column justify-content-center">
  <div class="absolute top-0 bg-shape-top bg-no-repeat bg-cover w-full"></div>
  <div id="home" class="landing-wrapper z-1">
    <div class="px-4 md:px-8 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
      <div class="flex gap-2 align-items-center text-white mr-0 lg:mr-6 select-none">
        <img src="../../../assets/images/headers/logo.png" class="w-2rem h-2rem" />
        <span class="font-bold text-3xl">PrintHubPod</span>
      </div>
      <a pripple class="p-element cursor-pointer block lg:hidden text-white" (click)="isShowMenu = !isShowMenu;">
        <i class="pi pi-bars text-4xl"></i>
      </a>
      <div
        id="menu"
        class="align-items-center flex-grow-1 justify-content-between lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none md:bg-transparent"
        [class]="{'hidden': !isShowMenu}"
      >
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          @for(menuItem of topNavMenu; track menuItem.label) {
          <li>
            <a
              routerLink="{{ menuItem.url }}"
              class="p-element flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap"
              >{{ menuItem.label }}</a
            >
          </li>
          }
        </ul>
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
          @for(menuItem of topLeftNavMenu; track menuItem.label; let last = $last) {
          <li>
            <a
              routerLink="{{ menuItem.url }}"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 text-lg white-space-nowrap"
              [class]="{ 'surface-100': last, 'text-900': last, 'lg:text-white': !last }"
              >{{ menuItem.label }}</a
            >
          </li>
          }
        </ul>
      </div>
    </div>
    <div class="px-4 md:px-8 z-2">
      <div class="grid justify-content-between mb-6 md:mb-8 mt-5">
        <div class="col-12 lg:col-6 text-center lg:text-left flex flex-column gap-3">
          <span class="font-bold text-4xl text-white text-center md:text-left w-full md:w-9">PrintHubPod Proudly Presents <br><i>A Revolution in Printing</i></span>
          <p class="w-full md:w-9 text-center md:text-left font-semibold text-white">Where modern design meets elegant craftsmanship</p>
          <a [routerLink]="['/about-us']"
            class="p-button px-6 py-2 w-15rem flex align-self-center md:align-self-start justify-content-center align-items-center surface-section font-bold cursor-pointer border-none hover:bg-primary-100 shadow-3 md:shadow-none"
            style="color: var(--primary-800)"
            >LEARN MORE</a
          >
        </div>
        <div class="col-12 text-center md:text-right lg:col-6">
          <img src="../../../assets/images/sample-dashboard_900.png" alt="" class="w-9 moveinright" />
        </div>
      </div>
      <div id="service-catalog" class="my-6 md:my-8 py-4">
        <div class="w-full text-center">
          <span class="block font-bold text-5xl mb-2">Meet PrintHubPod</span>
          <span class="block font-bold text-lg text-color-secondary">
            <h3>Reclaim your time by managing all your orders in one place.</h3>

            Our seamless fulfillment solutions connect all your sales channels, simplifying your workflow and freeing you to focus on what matters most-growing
            your business.
          </span>
        </div>
        <div class="grid mt-8">
          <div class="col-12 md:col-6 lg:col-3 text-center px-5">
            <mat-icon class="large-icon w-3rem h-3rem mb-4">new_releases</mat-icon>
            <span class="text-2xl font-bold block">Revolutionize Your Production with Modern Technology</span>
            <span class="font-bold block mt-3 text-color-secondary">
              Modern production systems harness the power of Direct to Film (DTF) technology, transforming the printing process. This cutting-edge method not
              only simplifies production but also boosts efficiency and elevates output quality to new heights.
            </span>
          </div>
          <div class="col-12 md:col-6 lg:col-3 text-center px-5">
            <mat-icon class="large-icon w-3rem h-3rem mb-4">speed</mat-icon>
            <span class="text-2xl font-bold block">Swift Support, Anytime You Need It</span>
            <span class="font-bold block mt-3 text-color-secondary">
              Our fast support services are designed to provide you with timely, reliable assistance. With a dedicated team committed to resolving your
              inquiries and issues quickly, we ensure minimal downtime so you can keep moving forward.
            </span>
          </div>
          <div class="col-12 md:col-6 lg:col-3 text-center px-5">
            <mat-icon class="large-icon w-3rem h-3rem mb-4">alarm_on</mat-icon>
            <span class="text-2xl font-bold block">24-Hour Turnaround, Guaranteed</span>
            <span class="font-bold block mt-3 text-color-secondary">
              Experience lightning-fast production with our 24-hour turnaround guarantee. We prioritize efficiency to deliver high-quality products swiftly,
              ensuring your orders meet even the tightest deadlines.
            </span>
          </div>
          <div class="col-12 md:col-6 lg:col-3 text-center px-5">
            <mat-icon class="large-icon w-3rem h-3rem mb-4">troubleshoot</mat-icon>
            <span class="text-2xl font-bold block">Effortless Tracking at Your Fingertips</span>
            <span class="font-bold block mt-3 text-color-secondary">
              Our tracking system is designed for speed and efficiency, allowing you to monitor activities with ease. With its powerful management tools, we
              provide streamlined oversight and complete accountability at every step of the process.
            </span>
          </div>
        </div>
      </div>
      <div id="contact-us" class="my-6 md:my-8 py-4 text-center">
        <span class="block font-bold text-5xl mb-3">Get in Touch with Us</span>
        <span class="block font-bold text-color-secondary text-xl">Experience the finest production tailored just for you.</span>
        <div class="grid justify-content-center lg:justify-content-between xl:justify-content-center mt-7">
          @for(contact of contactInfos; track contact.at1) {
          <div class="col-12 md:col-6 lg:col-4 xl:col-3 lg:p-3 xl:p-5 flex-shrink-0">
            <div class="card py-5 shadow-6 bg-bluegray-800 text-white h-full">
              <span class="block text-2xl font-bold mb-3">{{ contact.about1 }}</span>
              <span class="block text-xl font-semibold mb-3">{{ contact.at1 }}</span>
              @if (contact.about2) {
              <span class="block text-2xl font-bold mb-3">{{ contact.about2 }}</span>
              <span class="block text-xl font-semibold mb">{{ contact.at2 }}</span>
              } @if (contact.details) {
              <ul class="list-none mx-0 mt-3 p-0">
                @for(detail of contact.details; track detail) {
                <li class="font-semibold">{{ detail }}</li>
                }
              </ul>
              }
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
